import { createTheme } from '@mui/material/styles';

const spacing = 8;
const theme = createTheme({
    spacing: spacing,
    palette: {
        generic: {
            black: '#000000',
            white: '#ffffff',
        },
        navBar: {
            main: '#3f51b5',
            text: '#ffffff',
        },
        header: {
            main: '#3f51b5',
        },
        footer: {
            main: '#3f51b5',
        },
        body: {
            main: '#f5f5f5',
            header: {
                background: '#ffffff',
                color: '#3f51b5',
            }
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: spacing * 1,
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'menu' },
                    style: {
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    },
                },
            ],
        }
    },
});

export default theme;