import React from 'react';
import { HeaderAvatar, HeaderPopover, HeaderPopoverItem, HeaderPopoverItemText, PopoverDevider } from '../layoutStyled'
import avatarGenerator from '../../../utils/avatarGenerator';
import { useDispatch, useSelector } from 'react-redux'
import { toggleProfileHeaderMenu } from '../../../redux/profileHeader/profileHeaderSlice'
import { useSignOut, useUserAvatarUrl } from '@nhost/react';

const ProfileIcon = () => {
    const { signOut } = useSignOut()
    const dispatch = useDispatch()
    const ProfileIconRef = React.useRef()
    const userAvatarUrl = useUserAvatarUrl()
    const profileMenuOpen = useSelector((state) => state.profileHeader.headerProfileOpen)
    return (
        <>
            <HeaderAvatar
                onClick={() => dispatch(toggleProfileHeaderMenu())}
                {...avatarGenerator('Dave Amison')}
                src={userAvatarUrl}
                ref={ProfileIconRef}
            />
            <HeaderPopover
                anchorEl={ProfileIconRef?.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={profileMenuOpen}
                onClose={() => {
                    dispatch(toggleProfileHeaderMenu())
                }}
            >
                <HeaderPopoverItem href="/profile">
                    <HeaderPopoverItemText>
                        Profile
                    </HeaderPopoverItemText>
                </HeaderPopoverItem>
                <PopoverDevider variant="middle" />
                <HeaderPopoverItem>
                    <HeaderPopoverItemText>
                        Settings
                    </HeaderPopoverItemText>
                </HeaderPopoverItem>
                <PopoverDevider variant="middle" />
                <HeaderPopoverItem onClick={() => {
                    dispatch(toggleProfileHeaderMenu())
                    signOut()
                }}>
                    <HeaderPopoverItemText>
                        Logout
                    </HeaderPopoverItemText>
                </HeaderPopoverItem>
            </HeaderPopover>
        </>
    )
}

export default ProfileIcon;