import i18next from 'i18next';
import { useSelector } from 'react-redux'

const importAll = async (r) => {
    let resources = {};
    let imports = r.keys().map(async (key) => {
        let matchedKey = key.match(/\.\/(.*)\/(.*)\.json$/);
        let lang = matchedKey[1];
        let namespace = matchedKey[2];
        if (!resources[lang]) {
            resources[lang] = {};
        }
        resources[lang][namespace] = await r(key);
    });
    await Promise.all(imports);
    return resources;
};

const resources = await importAll(require.context('./', true, /\.\/(en|de)\/.*\.json$/));

const useLanguages = () => {
    const language = useSelector((state) => state.language.language);

    if (!i18next.isInitialized) {
        i18next.init({
            interpolation: { escapeValue: false },
            lng: language,
            resources: resources,
            preload: ['en', 'de'],
        }).catch((error) => {
            console.log(error);
        });
    }



    return i18next;
}

export default useLanguages;