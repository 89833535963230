import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageBase, PageContent, PageTitle } from '../../components/layout/layoutStyled';

const AboutPage = () => {
    const { t } = useTranslation('aboutpage')

    return (
        <PageBase component="main">
            <PageTitle variant='h5'>{t('pageTitle')}</PageTitle>
            <PageContent>

            </PageContent>
        </PageBase >
    );
}

export default AboutPage;