import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    headerProfileOpen: false
}

export const profileHeaderSlice = createSlice({
    name: 'headerProfile',
    initialState,
    reducers: {
        toggleProfileHeaderMenu: (state) => {
            state.headerProfileOpen = !state.headerProfileOpen
        }
    }
})

export const { toggleProfileHeaderMenu } = profileHeaderSlice.actions

export default profileHeaderSlice.reducer