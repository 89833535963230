import { styled } from '@mui/system';
import { Box, Typography, Popover, Avatar, Paper, Divider } from '@mui/material';

export const AppContainer = styled(Box)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
}));

export const BodyContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}));

export const NavBar = styled(Box)(({ theme, fullsize }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme?.palette?.navBar?.main ?? theme?.palette?.primary?.main,
    width: fullsize === 'true' ? '260px' : '50px',
    transition: 'width 0.3s',
    overflow: 'hidden',
}));

export const NavBarHeader = styled('img')(({ theme }) => ({
    width: '260px',
    height: '64px',
    padding: theme.spacing(1.5),
}));

export const NavItem = styled('a')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: theme.spacing(2, 2),
    gap: theme.spacing(1.25),
    color: theme?.palette?.navBar?.text ?? theme?.palette?.primary?.main,
    textDecoration: 'none',
}));

export const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme?.palette?.header?.main ?? theme?.palette?.primary?.main,
    width: '100%',
    minHeight: '64px',
    padding: theme.spacing(0, 2),
}));

export const HeaderIconsGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
}));

export const HeaderAvatar = styled(Avatar)(({ theme }) => ({
    maxHeight: '32px',
    maxWidth: '32px',
    cursor: 'pointer',
    fontSize: '0.875rem',
}));

export const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme?.palette?.footer?.main ?? theme?.palette?.primary?.main,
    width: '100%',
    minHeight: '64px',
}));

export const PageBase = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme?.palette?.body?.main ?? theme?.palette?.primary?.main,
    width: '100%',
    flexGrow: 1,
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    width: '100%',
    backgroundColor: theme?.palette?.body?.header?.background ?? theme?.palette?.primary?.main,
    color: theme?.palette?.body?.header?.color ?? theme?.palette?.primary?.main,
    padding: theme.spacing(2),
    fontWeight: 'bold',
}));

export const PageContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(2),
    flexGrow: 1,
}));

export const FlagIcon = styled('img')(({ theme }) => ({
    maxHeight: '32px',
}));

export const HeaderPopover = styled(Popover)(({ theme }) => ({
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(1),
}));

export const HeaderPopoverItem = styled('a')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(1),
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: theme?.palette?.primary?.light,
    },
    position: 'relative',
    '&:visited': {
        color: 'inherit',
    },
}));

export const HeaderPopoverItemText = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 0.5),
}));

export const TableContainer = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
}));

export const PopoverDevider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme?.palette?.primary?.light,
}));