import React, { useEffect, useState } from 'react';
import { PageBase, PageContent, PageTitle, TableContainer } from '../../components/layout/layoutStyled';
import { useSelector, useDispatch } from 'react-redux';
import { deleteItem, removeErrorMessage, removeWarningMessage, clearCart } from '../../redux/cart/cartSlice';
import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import useAlert from '../../hooks/useAlert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PosPage = () => {
    const [openClearDialog, setOpenClearDialog] = useState(false);
    const cart = useSelector((state) => state.cart);
    const { products, totalCartQuantity, totalCartAmount, warningMessage, errorMessage } = cart;
    const dispatch = useDispatch();
    const [showAlert, AlertSnackbar] = useAlert();
    const TAX_RATE = 0.20;

    useEffect(() => {
        if (warningMessage) {
            showAlert(warningMessage, 'warning');
            dispatch(removeWarningMessage())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warningMessage])

    useEffect(() => {
        if (errorMessage) {
            showAlert(errorMessage, 'error');
            dispatch(removeErrorMessage())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    const handleClearCart = () => {
        dispatch(clearCart())
        setOpenClearDialog(false);
    }

    const handleCloseClearCart = () => {
        setOpenClearDialog(false);
    }

    const handleOpenClearCart = () => {
        setOpenClearDialog(true);
    }


    return (
        <PageBase component="main">
            <PageTitle variant='h5'>POS Page</PageTitle>
            <PageContent>
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: '600' }} colSpan={3}>
                                    Details
                                </TableCell>
                                <TableCell colSpan={1} align="right" sx={{ fontWeight: '600' }}>Price</TableCell>
                                <TableCell colSpan={1} align="center" sx={{ fontWeight: '600' }}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600' }}>Desc</TableCell>
                                <TableCell align="right" sx={{ fontWeight: '600' }}>Qty.</TableCell>
                                <TableCell align="right" sx={{ fontWeight: '600' }}>Unit</TableCell>
                                <TableCell align="right" sx={{ fontWeight: '600' }}>Sum</TableCell>
                                <TableCell align="center" sx={{ fontWeight: '600' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((item) => (
                                <TableRow key={item.name}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{item.price}</TableCell>
                                    <TableCell align="right">{(item.price * item.quantity).toFixed(2)}</TableCell>
                                    <TableCell align="center" width={200}>
                                        <Button variant="outlined" size='small' color="error" onClick={() => dispatch(deleteItem(item))}>Remove One Item</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell rowSpan={3} />
                                <TableCell colSpan={2} sx={{ fontWeight: '600' }}>Total Quantity</TableCell>
                                <TableCell align="right">{totalCartQuantity}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ fontWeight: '600' }}>Subtotal</TableCell>
                                <TableCell align="right">{(totalCartAmount - (totalCartAmount * TAX_RATE)).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600' }}>Tax</TableCell>
                                <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                                <TableCell align="right">{(totalCartAmount * TAX_RATE).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={1} />
                                <TableCell colSpan={2} sx={{ fontWeight: '600' }}>Total</TableCell>
                                <TableCell align="right">{totalCartAmount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button sx={{ marginLeft: '0px' }} variant="contained" color="primary" size="large" fullWidth>Checkout</Button>
                <Button sx={{ marginLeft: '0px' }} variant="contained" color="error" size="large" fullWidth onClick={() => handleOpenClearCart()}>Clear Cart</Button>
                <Dialog
                    open={openClearDialog}
                    onClose={handleCloseClearCart}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Clear Cart
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are You Sure You Want to Clear the Cart?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color='success' variant="contained" fullWidth onClick={handleClearCart}>Yes</Button>
                        <Button color='error' variant="contained" fullWidth onClick={handleCloseClearCart} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </PageContent>
            <AlertSnackbar />
        </PageBase >
    );
}

export default PosPage;