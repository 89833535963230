import React from 'react';
import { useDispatch } from 'react-redux'
import { changeTheme } from '../../redux/theme/themeSlice'
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageBase, PageTitle, PageContent } from '../../components/layout/layoutStyled';
import useAlert from '../../hooks/useAlert';


const HomePage = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('homepage')
    const [showAlert, AlertSnackbar] = useAlert();

    const handleClick = () => {
        showAlert('This is a success message!', 'success');
    };
    return (
        <PageBase component="main">
            <PageTitle variant='h5'>{t('pageTitle')}</PageTitle>
            <PageContent>
                <Box>
                    <Typography variant='h6'>{t('themeSelector.heading')}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(changeTheme('default'))}
                    >
                        {t('themeSelector.buttons.default')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(changeTheme('main'))}
                    >
                        {t('themeSelector.buttons.main')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        {t('alerts.buttons.alert')}
                    </Button>
                </Box>
                <AlertSnackbar />
            </PageContent>
        </PageBase >
    );
}

export default HomePage;