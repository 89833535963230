import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageBase, PageContent, PageTitle } from '../../components/layout/layoutStyled';
import { useUserData } from '@nhost/react';
import avatarGenerator from '../../utils/avatarGenerator';
import { ProfileAvatar, ProfileCard, ProfileContainer, ProfileCardTop, ProfileCardContent, ProfileContentLabel, ProfileContentField } from '../../components/styled/profile';


const Profile = () => {
    const { t } = useTranslation('profile')
    const user = useUserData()
    const { id, avatarUrl, displayName, email, locale, phoneNumber, phoneNumberVerified, activeMfaType, createdAt, metadata } = user

    let avatarProps = {}
    if (avatarUrl) {
        avatarProps = {
            src: avatarUrl
        }
    } else {
        avatarProps = avatarGenerator(displayName)
    }
    return (
        <PageBase component="main">
            <PageTitle variant='h5'>{t('pageTitle')}</PageTitle>
            <PageContent>
                <ProfileContainer>
                    <ProfileCard>
                        <ProfileAvatar
                            {...avatarProps}
                        />
                        <ProfileCardTop />
                        <ProfileCardContent>
                            <ProfileContentLabel>
                                User ID
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {id}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Display Name
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {displayName}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Email
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {email}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Locale
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {locale}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Phone Number
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {phoneNumber}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Phone Number Verified
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {phoneNumberVerified ? 'Yes' : 'No'}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Active MFA Type
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {activeMfaType}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Created At
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {createdAt}
                            </ProfileContentField>
                            <ProfileContentLabel>
                                Metadata
                            </ProfileContentLabel>
                            <ProfileContentField>
                                {JSON.stringify(metadata)}
                            </ProfileContentField>
                        </ProfileCardContent>
                    </ProfileCard>
                </ProfileContainer>
            </PageContent>
        </PageBase >
    );
}

export default Profile;