import React from 'react';
import LanguageSelector from '../LanguageSelector';
import ProfileIcon from '../ProfileIcon';
import { Header, HeaderIconsGroup } from '../layoutStyled';
import { useDispatch } from 'react-redux'
import { toggleMenu } from '../../../redux/menu/menuSlice'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

const HeaderSection = () => {
    const dispatch = useDispatch()
    return (
        <Header component="header">
            <IconButton onClick={() => dispatch(toggleMenu())}>
                <MenuIcon />
            </IconButton>
            <HeaderIconsGroup>
                <LanguageSelector />
                <ProfileIcon />
            </HeaderIconsGroup>
        </Header>
    );
}

export default HeaderSection;