import { createSlice } from '@reduxjs/toolkit'
import i18next from 'i18next'

const initialState = {
    language: 'en',
    headerMenuOpen: false
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload
            i18next.changeLanguage(action.payload)
        },
        toggleLanguageHeaderMenu: (state) => {
            state.headerMenuOpen = !state.headerMenuOpen
        }
    }
})

export const { changeLanguage, toggleLanguageHeaderMenu } = languageSlice.actions

export default languageSlice.reducer