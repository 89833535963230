import React from 'react';
import { useSelector } from 'react-redux'
import { NavBar, NavBarHeader, NavItem, } from '../layoutStyled';
import { Home, Info, PointOfSale } from '@mui/icons-material';
import { Typography } from '@mui/material'

const NavSection = () => {
    const fullSize = useSelector((state) => state.menu.fullSize)
    return (
        <NavBar component="nav" fullsize={fullSize.toString()}>
            <NavBarHeader src={'https://uploads-ssl.webflow.com/5fa98e8fcb4e7d057da9cad0/5fcd2b56f98c0b2535ce929b_Playground%20Title-p-1080.png'} />
            <NavItem href={'/'}>
                <Home />
                <Typography variant='menu'>
                    Home
                </Typography>
            </NavItem>
            <NavItem href={'/about'}>
                <Info />
                <Typography variant='menu'>
                    About
                </Typography>
            </NavItem>
            <NavItem href={'/pos'}>
                <PointOfSale />
                <Typography variant='menu'>
                    POS
                </Typography>
            </NavItem>
        </NavBar>
    );
}

export default NavSection;