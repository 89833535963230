import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HeaderSection from './components/layout/Header';
// import FooterSection from './components/layout/Footer';
import NavSection from './components/layout/Nav';
import { AppContainer, BodyContainer } from './components/layout/layoutStyled';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import Profile from './pages/Profile';
import PosPage from './pages/Pos';
import useScan from './hooks/useScan';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/pos",
    element: <PosPage />,
  },
  {
    path: "*",
    element: <div>Not Found</div>,
  },
]);


function App() {
  useScan();
  return (
    <>
      <AppContainer>
        <NavSection />
        <BodyContainer>
          <HeaderSection />
          <RouterProvider router={router} />
          {/* <FooterSection /> */}
        </BodyContainer>
      </AppContainer>
    </>

  );
}

export default App;
