import React from 'react';
import ReactDOM from 'react-dom/client';
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { I18nextProvider } from "react-i18next";
import useLanguages from './translations/useLanguages';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material';
import useTheme from './theme/useTheme';
import { NhostProvider, useAuthenticationStatus } from '@nhost/react';
import { nhost } from './nhost';
import Login from './pages/Login';
import Loading from './components/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const theme = useTheme();
  const i18next = useLanguages();
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        {isAuthenticated && !isLoading && <App />}
        {isAuthenticated && isLoading && <Loading />}
        {!isAuthenticated && !isLoading && <Login />}
      </ThemeProvider >
    </I18nextProvider>
  );
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NhostProvider nhost={nhost}>
        <Main />
      </NhostProvider>
    </PersistGate>
  </Provider >
);
