import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    totalCartQuantity: 0,
    totalCartAmount: 0,
    warningMessage: null,
    errorMessage: null
}

export const cartSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const item = action.payload
            const existingItem = state.products.find(product => product.id === item.id)
            if (existingItem) {
                existingItem.quantity = existingItem.quantity + 1
            } else {
                state.products.push({
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    barcode: item.barcode,
                    quantity: 1
                })
            }

            state.totalCartQuantity += 1
            state.totalCartAmount = parseFloat(Math.round((Number(state.totalCartAmount) + Number(item.price)) * 100) / 100).toFixed(2)
        },
        deleteItem: (state, action) => {
            const item = state.products.find(product => product.id === action.payload.id)
            if (item) {
                item.quantity -= 1
                state.totalCartQuantity -= 1
                state.totalCartAmount = parseFloat(Math.round((Number(state.totalCartAmount) - Number(item.price)) * 100) / 100).toFixed(2)

                if (item.quantity === 0) {
                    state.products = state.products.filter(product => product.id !== action.payload.id)
                }
            }
        },
        addWarningMessage: (state, action) => {
            state.warningMessage = action.payload
        },
        removeWarningMessage: (state) => {
            state.warningMessage = null
        },
        addErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        removeErrorMessage: (state) => {
            state.errorMessage = null
        },
        clearCart: (state) => {
            state.products = []
            state.totalCartQuantity = 0
            state.totalCartAmount = 0
            state.warningMessage = null
            state.errorMessage = null
        }
    },
})

export const { addItem, deleteItem, addWarningMessage, removeWarningMessage, addErrorMessage, removeErrorMessage, clearCart } = cartSlice.actions

export default cartSlice.reducer