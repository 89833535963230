import { createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

const initialState = {
    theme: 'default'
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme: (state, action) => {
            state.theme = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(REHYDRATE, (state, action) => {
            if (action.payload) {
                state.theme = action.payload.theme.theme
            }
        })
    }
})

export const { changeTheme } = themeSlice.actions

export default themeSlice.reducer