import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addItem, addErrorMessage, addWarningMessage } from '../redux/cart/cartSlice';
import { nhost } from '../nhost';
import onScan from 'onscan.js/onscan.js';

function useScan() {
    const dispatch = useDispatch();

    useEffect(() => {
        onScan.attachTo(document);
        return () => {
            onScan.detachFrom(document);
        }
    }, []);

    document.addEventListener('scan', async function (props) {
        const { detail } = props;
        const { scanCode: barcode, qty: quantity } = detail;

        const getRemoteProduct = `
        query Products {
            products(where: { barcode: { _eq: "${barcode}" } }) {
                id
                display_name
                price
                barcode
            }
        }`

        const { data, error } = await nhost.graphql.request(getRemoteProduct)

        const product = data.products[0];

        if (error) {
            dispatch(addErrorMessage(error.message || 'Error fetching product'));
            return;
        } else if (!product) {
            dispatch(addWarningMessage('Product not found'));
            return
        } else {
            dispatch(addItem({
                id: product.id,
                name: product.display_name,
                price: product.price,
                barcode,
                quantity
            }));
        }

        return

    });
}

export default useScan;
