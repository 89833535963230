import { styled } from '@mui/system';
import { Card, Avatar, Box } from '@mui/material';

export const ProfileContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    height: '100%',
    width: '100%',
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    height: '90px',
    width: '90px',
    zIndex: 1,
    top: '10px',
    border: 'none',
    boxShadow: '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.12)',
}));

export const ProfileCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '400px',
    display: 'flex',
    height: '90%',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: theme.spacing(1),
}));

export const ProfileCardTop = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    height: '50px',
    width: '400px',
    position: 'absolute',
    top: '50px',
    borderRadius: theme.spacing(1, 1, 0, 0),
}));

export const ProfileCardContent = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '100px',
    paddingTop: theme.spacing(2),
    borderRadius: theme.spacing(0, 0, 1, 1),
}));

export const ProfileContentLabel = styled(Box)(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'black',
    marginBottom: theme.spacing(1),
}));

export const ProfileContentField = styled(Box)(({ theme }) => ({
    fontSize: '1rem',
    color: 'black',
    marginBottom: theme.spacing(1),
}));

