import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    fullSize: true
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.fullSize = !state.fullSize
        },
    },
})

export const { toggleMenu } = menuSlice.actions

export default menuSlice.reducer