import React, { useEffect } from "react";
import { LoginBox, LoginContainer, LoginButton, LoginInput, LoginTitle } from "./loginStyled";
import { useSignInEmailPassword } from "@nhost/react";
import useAlert from "../../hooks/useAlert";
import Loading from "../../components/Loading";

const Login = () => {
    const [showAlert, AlertSnackbar] = useAlert();
    const {
        signInEmailPassword,
        needsEmailVerification,
        isLoading,
        isSuccess,
        isError,
        error
    } = useSignInEmailPassword()

    useEffect(() => {
        if (isError) {
            showAlert(error?.message, 'error')
        }
        // eslint-disable-next-line
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            showAlert('Login successful!', 'success')
        }
        // eslint-disable-next-line
    }, [isSuccess])

    useEffect(() => {
        if (needsEmailVerification) {
            showAlert('Please verify your email', 'info')
        }
        // eslint-disable-next-line
    }, [needsEmailVerification])


    const handleFormSubmit = async (e, v) => {
        e.preventDefault()

        let username = e?.target[0]?.value ?? ''
        let password = e?.target[1]?.value ?? ''

        await signInEmailPassword(username, password)
    }

    return (
        <LoginContainer>
            {!isLoading ? (
                <LoginBox onSubmit={handleFormSubmit}>
                    <LoginTitle>Login</LoginTitle>
                    <LoginInput type='email' placeholder='Email' autocomplete="username" required />
                    <LoginInput type='password' placeholder='Password' autocomplete="current-password" required />
                    <LoginButton type='submit'>Login</LoginButton>
                </LoginBox>
            ) : (
                <Loading />
            )}
            <AlertSnackbar />
        </LoginContainer>
    )
}

export default Login;