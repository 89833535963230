import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const blacklist = []

const importAll = (r) => {
    return r.keys().reduce((resources, key) => {
        let matchedKey = key.match(/\.\/(.*)\/(.*)Slice\.js$/);
        let namespace = matchedKey[1];
        resources[namespace] = r(key);
        return resources;
    }, {});
}

const makeReducers = (resources) => {
    return Object.keys(resources).reduce((reducers, key) => {
        reducers[key] = resources[key].default;
        return reducers;
    }, {});
}

const resources = importAll(require.context('./', true, /Slice\.js$/));
const reducers = makeReducers(resources);


const persistConfig = {
    key: 'root',
    storage,
    blacklist
}

const rootReducer = combineReducers(reducers)

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = configureStore(
    {
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    }
)
let persistor = persistStore(store)

export { store, persistor }
