import { createTheme } from '@mui/material/styles';
import { nhost } from '../nhost';

const getRemoteTheme = `
    query Themes {
    themes(where: { display_name: { _eq: "main" } }) {
        id
        display_name
        data
    }
}`

let themeValues = {}

async function fetchRemoteTheme() {
    await nhost.graphql.request(getRemoteTheme).then((result) => {
        if (result?.data?.themes?.length > 0) {
            themeValues = {
                ...result?.data.themes[0].data
            }
        }
    }).catch((error) => {
        console.log(error);
    })

    const defaultValues = {
        palette: {
            primary: {
                main: '#f88d9f',
            },
        },
    }

    const theme = createTheme({ ...defaultValues, ...themeValues });
    return theme;
}
const theme = await fetchRemoteTheme();

export default theme;