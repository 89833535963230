import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage, toggleLanguageHeaderMenu } from '../../../redux/language/languageSlice'
import { FlagIcon, HeaderPopover, HeaderPopoverItem } from '../layoutStyled';
import { IconButton } from '@mui/material';


const LanguageSelectorItem = ({ local, alt, dispatch, t }) => {
    return (
        <HeaderPopoverItem onClick={() => {
            dispatch(changeLanguage(local))
            dispatch(toggleLanguageHeaderMenu())
        }}>
            <FlagIcon src={`/images/flags/${local}.png`} alt={alt} />
            {t(`language-selector.languages.${local}`)}
        </HeaderPopoverItem>
    )
}

const LanguageSelector = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const anchorRef = React.useRef()
    const headerMenuOpen = useSelector((state) => state.language.headerMenuOpen)
    return (
        <>
            <IconButton onClick={() => dispatch(toggleLanguageHeaderMenu())} ref={anchorRef}>
                <FlagIcon src="/images/flags/en.png" alt="English" />
            </IconButton>
            <HeaderPopover
                anchorEl={anchorRef?.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={headerMenuOpen}
                onClose={() => dispatch(toggleLanguageHeaderMenu())}
            >
                <LanguageSelectorItem local="en" alt="English" dispatch={dispatch} t={t} />
                <LanguageSelectorItem local="de" alt="German" dispatch={dispatch} t={t} />
                <LanguageSelectorItem local="es" alt="Spanish" dispatch={dispatch} t={t} />
                <LanguageSelectorItem local="fr" alt="French" dispatch={dispatch} t={t} />
            </HeaderPopover>
        </>
    )
}

export default LanguageSelector;